<template>
    <div class="absolute inset-0 h-fit w-full">
        <div v-if="!isFetchingGifts">
            <img
                :src="banner"
                class="h-[180px] w-full"
                alt="detail-cover"
                @error="
                    (e) =>
                        (e.target.src =
                            '/assets/imgs/views/discover/app-cover.png')
                "
            />

            <div class="inline-flex w-full flex-col gap-6 px-6 pb-4">
                <div
                    class="flex justify-between border-b border-[#dddfec] py-6 items-start"
                >
                    <div class="inline-flex items-start gap-4">
                        <!-- Logo -->
                        <img
                            :src="logo"
                            alt="Logo"
                            class="size-20 rounded-2xl"
                            @error="
                                (e) =>
                                    (e.target.src =
                                        '/assets/imgs/utils/token/default.png')
                            "
                        />

                        <!-- Title -->
                        <div class="">
                            <div class="text-sm font-bold text-[#111111]">
                                {{ title }}
                            </div>
                            <div class="pb-3 text-xs text-[#aaaaaa]">
                                {{ keyword }}
                            </div>
                            <a
                                target="_blank"
                                :href="miniappLink"
                                class="center-flex h-8 w-20 rounded-lg bg-[#ff5c8e] px-4 text-sm font-bold text-white hover:text-white"
                            >
                                {{ $t("utils.open") }}
                            </a>
                        </div>
                    </div>
                    <button @click="openInvitationPanel">
                        <svg-icon
                            class="size-5 text-[#9699B5] hover:cursor-pointer"
                            type="mdi"
                            :path="mdiShareVariant"
                        ></svg-icon>
                    </button>
                </div>
                <!-- Desc -->
                <p class="text-xs text-[#aaaaaa]">
                    {{ description }}
                </p>
                <div v-if="gifts.length > 0" class="flex flex-col gap-4">
                    <DetailGift
                        v-for="item in gifts"
                        :key="item.id"
                        :id="item.id"
                        :logo="item.logo"
                        :progress="item.progress"
                        :token="item.token"
                        :totalAmount="item.totalAmount"
                        :received="item.received"
                    />
                </div>
            </div>
        </div>
        <Loading v-else :full-screen="true" />

        <InvitationPanel
            :title="$t('detail.invitationPanel.title')"
            :subTitle="$t('detail.invitationPanel.subtitle')"
            :showQrCode="false"
            :shareLink="panel.shareLink"
            :inviteLink="panel.inviteLink"
            :copy-text="panel.copyText"
            :showMainBtn="false"
            ref="InvitationPanel"
        />
    </div>
</template>

<script>
import envelopeApi from "@/apis/envelope";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiShareVariant } from "@mdi/js";
import { mapGetters } from "vuex";
import Decimal from "decimal.js";

import Loading from "@/components/Loading.vue";
import InvitationPanel from "../invitation/components/InvitationPanel.vue";
import DetailGift from "./components/DetailGift.vue";

export default {
    name: "Detail",
    components: {
        DetailGift,
        Loading,
        SvgIcon,
        InvitationPanel,
    },
    data: () => ({
        url: process.env.VUE_APP_SERVER_URL,
        banner: null,
        logo: null,
        title: "TonGift",
        keyword: "miniapp keyword",
        description: "",
        gifts: [],
        isFetchingGifts: false,
        miniappLink: "#",
        mdiShareVariant,
    }),
    computed: {
        ...mapGetters(["discover", "platform", "userInfo"]),
        currentPlatform() {
            return this.platform;
        },
        currentUserName() {
            return (
                (this.userInfo &&
                    this.userInfo.user &&
                    this.userInfo.user.tg_info &&
                    this.userInfo.user.tg_info.first_name) ||
                null
            );
        },
        currentInviteLink() {
            return `${process.env.VUE_APP_BOT_URL}/${process.env.VUE_APP_MINIAPP}?startapp=d_${this.$route.query.id}`;
        },
        panel() {
            let shareText = `\n\nI just found an awesome app here! Come check it out and experience it with me!\n\n👆Click Link above to open 🌈⬆️`;
            shareText = encodeURIComponent(shareText);
            const shareLink =
                this.currentPlatform === "telegram"
                    ? `https://t.me/share/url?url=${this.currentInviteLink}&text=${shareText}`
                    : `https://social-plugins.line.me/lineit/share?url=${this.currentInviteLink}&text=${shareText}`;
            const copyText = `${this.currentInviteLink}\n\nI just found an awesome app here! Come check it out and experience it with me!\n\n👆Click Link above to open 🌈⬆️`;
            return {
                shareLink,
                copyText,
                inviteLink: this.currentInviteLink,
            };
        },
    },
    methods: {
        async handleRenderGifts(giftIds) {
            for (const giftId of giftIds) {
                try {
                    const res = await envelopeApi.getEnvelope(giftId);
                    if (res.status === 200) {
                        const { active, info, left, leftNumber } = res.data;
                        const receivedGiftQuantity = String(
                            new Decimal(info?.number).minus(
                                new Decimal(leftNumber),
                            ),
                        );

                        this.gifts.push({
                            id: giftId,
                            active,
                            logo: `/assets/imgs/utils/token/${info?.token}.png`,
                            progress:
                                Number(
                                    (
                                        (Number(receivedGiftQuantity) /
                                            info?.number) *
                                        100
                                    ).toFixed(2),
                                ) ?? 0,
                            totalAmount: String(info?.totalAmount) || "0",
                            token: info?.token,
                            received: {
                                number: receivedGiftQuantity || "0",
                                totalNumber: info?.number ?? 0,
                                amount:
                                    Math.round(
                                        Number(
                                            info.totalAmount -
                                                left?.$numberDecimal,
                                        ),
                                    ) || 0,
                                totalAmount: info.totalAmount ?? 0,
                            },
                        });
                    }
                } catch (err) {
                    console.error("getGiftInfo err: ", err);
                }
            }
        },
        openInvitationPanel() {
            this.$refs.InvitationPanel.show();
        },
        updateDetailInfo() {
            const currentDetail = this.discover?.apps?.find(
                (app) => app._id === this.$route.query.id,
            );

            if (currentDetail) {
                this.banner = `${this.url}${currentDetail.image}`;
                this.logo = `${this.url}${currentDetail.icon}`;
                this.keyword = currentDetail.keyword.join(", ");
                this.title = currentDetail.title;
                this.description = currentDetail.description.en;
                this.miniappLink = currentDetail.link;
                if (currentDetail.gift.length > 0) {
                    this.handleRenderGifts(currentDetail.gift);
                }
            }
        },
    },
    async created() {
        this.isFetchingGifts = true;

        await this.$store.dispatch("updateDiscover");
        await this.$store.dispatch("updateUserInfo");
        this.updateDetailInfo();
        this.isFetchingGifts = false;
    },
};
</script>

<style lang="scss" scoped></style>
