import { render, staticRenderFns } from "./ReceiveGifts.vue?vue&type=template&id=743e914e&scoped=true"
import script from "./ReceiveGifts.vue?vue&type=script&lang=js"
export * from "./ReceiveGifts.vue?vue&type=script&lang=js"
import style0 from "./ReceiveGifts.vue?vue&type=style&index=0&id=743e914e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "743e914e",
  null
  
)

export default component.exports