<template>
    <div class="absolute inset-0 size-full bg-[#f2f2f7]">
        <img
            src="/assets/imgs/views/wallet/wallet-bg.svg"
            alt=""
            class="absolute left-0 top-0 z-0 w-full object-contain"
        />
        <div
            class="no-scrollbar relative flex size-full flex-col items-center gap-6 overflow-x-hidden px-6 py-4"
        >
            <DiscoverApp :list="handleList.app" />
            <Popular :list="handleList.popular" />
            <div
                v-if="
                    !isFetchingGifts &&
                    handleList.theme &&
                    handleList.theme.length > 0
                "
                class="flex w-full flex-col gap-6"
            >
                <Essential
                    v-for="(theme, index) in handleList.theme"
                    :key="index"
                    :title="theme.title"
                    :description="theme.description"
                    :appList="theme.apps"
                />
            </div>

            <Loading :fullScreen="true" v-else />
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import envelopeApi from "@/apis/envelope";

import Popular from "./components/popular/Popular.vue";
import Essential from "./components/essential/Essential.vue";
import DiscoverApp from "./components/app/DiscoverApp.vue";
import Loading from "@/components/Loading.vue";

export default {
    name: "Discover",
    components: {
        DiscoverApp,
        Popular,
        Essential,
        Loading,
    },
    data: () => ({
        url: process.env.VUE_APP_SERVER_URL,
        currentApps: [],
        currentLocale: null,
        isFetchingGifts: false,
        isLoggedIn: false,
        userProfile: {
            displayName: "",
            pictureUrl: "",
            email: "",
        },
    }),
    computed: {
        ...mapGetters(["discover"]),
        handleList() {
            if (!this.discover)
                return {
                    app: [],
                    popular: [],
                    theme: [],
                };
            // App
            const appList = this.discover?.apps
                ?.filter((app) => app.banner)
                ?.map((app) => ({
                    ...app,
                    description: app.description.en,
                    logo: app.icon,
                    detailLink: `/detail?id=${app._id}`,
                    miniappLink: app.link ?? "#",
                    banner: app.image,
                }));
            // Popular
            const popularList = this.discover?.apps
                ?.filter((app) => app.popular)
                ?.map((app) => ({
                    ...app,
                    detailLink: `/detail?id=${app._id}`,
                    miniappLink: app.link ?? "#",
                    imgSrc: `${this.url}${app.icon}`,
                }));

            // Essential
            const themeList = this.discover?.themes?.map((theme) => {
                const apps = this.currentApps.filter((app) =>
                    app.themes.includes(theme._id),
                );

                return {
                    title: theme.title[this.currentLocale] ?? theme.title.en,
                    description:
                        theme.description[this.currentLocale] ??
                        theme.description.en,
                    apps,
                };
            });

            return {
                app: appList ?? [],
                popular: popularList ?? [],
                theme: themeList ?? [],
            };
        },
    },
    methods: {
        truncateNumber(numStr) {
            const MAX_SHOW_DIGITS = 5;
            if (numStr.length <= MAX_SHOW_DIGITS) {
                return numStr;
            }
            const firstPart = numStr.slice(0, 3);
            const lastPart = numStr.slice(-2);
            return `${firstPart}...${lastPart}`;
        },
        async handleFetchGift(giftId) {
            try {
                const res = await envelopeApi.getEnvelope(giftId);
                if (res.status === 200) {
                    const { info } = res.data;
                    return {
                        amount: this.truncateNumber(info?.totalAmount) || "0",
                        token: info?.token,
                    };
                }
                return null;
            } catch (err) {
                console.error("getGiftInfo err: ", err);
                return null;
            }
        },
        async handleAppGifts() {
            if (!this.discover?.apps) return;
            const handledApps = await Promise.all(
                this.discover.apps.map(async (app) => {
                    // Fetch gifts
                    let rewards = [];
                    if (app.gift.length > 0) {
                        rewards = await Promise.all(
                            app.gift
                                .slice(0, 2)
                                .map((giftId) => this.handleFetchGift(giftId)),
                        );
                    }

                    return {
                        appName: app.title,
                        description:
                            app.description[this.currentLocale] ??
                            app.description.en,
                        logo: `${this.url}${app.icon}`,
                        detailLink: `/detail?id=${app._id}`,
                        miniappLink: app.link ?? "#",
                        keyword: app.keyword.join(", "),
                        rewards: rewards.filter((reward) => reward),
                        themes: app.themes,
                    };
                }),
            );

            this.currentApps = handledApps;
        },
    },
    async created() {
        this.currentLocale = this.$i18n.locale;
        this.isFetchingGifts = true;
        if (!this.discover) {
            await this.$store.dispatch("updateDiscover");
        }
        await this.handleAppGifts();
        this.isFetchingGifts = false;
    },
};
</script>
